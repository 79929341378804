import React, { useState } from 'react';

type FAQItemProps = {
    question: string;
    answer: string;
    list?: string[];
    conclusion?: string;
    isOpen: boolean;
    onClick: () => void;
};

const FAQItem: React.FC<FAQItemProps> = ({ question, answer, list, conclusion, isOpen, onClick }) => {
    return (
        <div className={`border-b border-white-16 !mt-0 py-4 hover:bg-white-8 ${isOpen ? 'bg-white-8' : ''}`}>
            <button
                className="px-6 w-full text-left flex justify-between items-center text-white-68 text-lg font-semibold"
                onClick={onClick}
            >
                {question}
                <img
                    src="/icons/chevronDown.svg"
                    className={`transform transition-transform duration-500 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                    alt="Toggle"
                />
            </button>
            <div
                className={`overflow-hidden transition-max-height duration-500 ease-in-out ${isOpen ? 'max-h-40 mt-4' : 'max-h-0'}`}
                style={{ maxHeight: isOpen ? `${(answer.length + (conclusion ? conclusion.length : 0) + (list ? list.reduce((prev, current) => { return prev + current.length}, 0) : 0)) * 0.97}px` : '0px' }}
            >
                {answer.split('\n').map((item, index) => <p className={`px-6 ${index > 0 ?'mt-2.5' : ''}`} key={index}>{item}</p>)}
                {list&& <ol>
                    {list.map((item, index) => <li className="px-6 mt-2.5" key={index}>{index+1}.{item}</li>)}
                </ol>}
                {conclusion&& <p className="px-6 mt-2.5">{conclusion}</p>}
            </div>
        </div>
    );
};


const FAQAccordion: React.FC = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const faqs = [
        {
            question: 'Why should I use Expat Pie?',
            answer: 'Job hunting is often perceived as straightforward: apply, interview, and sign the offer. However, our experience has shown that finding a job, especially one with visa sponsorship, can be quite complex. It requires knowledge of immigration rules, understanding the economic climate, and awareness of cultural differences in your destination country to present yourself effectively and negotiate favorable terms.\n' +
                'With years of experience in career consulting, we created Expat Pie to streamline this process. Our platform brings together top HR and Technical mentors who guide you through your challenges. We also offer hard-to-find jobs with visa sponsorship and relocation packages. This one-stop shop is designed to empower you, expand your opportunities, secure better offers, and accelerate your career growth.'
        },
        {
            question: 'What kind of job opportunities does Expat Pie offer?',
            answer: 'We specialize in tech industry roles such as software engineers, developers, data engineers, and product managers, product designers, …etc.\n' +
                'All positions come with visa sponsorship and relocation packages.'
        },
        {
            question: 'How does Expat Pie assist with interview preparation?',
            answer: 'Interviewing for tech positions often involves multiple stages: phone screen, technical task, technical interview, HR/cultural interview, and finally, the offer. Here\'s how Expat Pie supports you through each step:',
            list: [
                'Screening Interview: Our HR mentors help you make a strong first impression and pass the crucial initial phone screen.',
                'Technical Task: Receive feedback from our Technical mentors on your task/assessment before submission to ensure it\'s polished and meets high standards.',
                'Technical Interview: We offer mock technical interviews to help you prepare for presenting your task and answering technical questions confidently.',
                'Cultural Interview: Our HR mentors assist in the final stage by enhancing your soft skills and cultural understanding, ensuring you\'re well-prepared for the HR/cultural interview.',
            ],
            conclusion: 'By guiding you through each phase, we help you present your best self and increase your chances of securing the job.\n'
        },
        {
            question: 'Can Expat Pie help me negotiate my job contract?',
            answer: 'Absolutely! Our experienced mentors provide expert guidance on contract negotiation strategies. They help you understand key contract terms, evaluate offers, and negotiate for better salary, benefits, and relocation support. Additionally, our HR mentors are knowledgeable about labor laws, which is beneficial for effective negotiation. With their advice, you can confidently secure the best possible terms for your new role.'
        },
        {
            question: 'How do I apply for jobs through Expat Pie? Is it free?',
            answer: 'Yesss, the great news is applying for jobs through Expat Pie is completely free with no limitations. Whether you use our mentorship services or not, you are always welcome to explore and apply for positions on our job platform.\n' +
                'To access our job platform, simply select “Job Platform” from the header. Use the filters to refine your search and find positions that best match your skills and preferences, making your job search more efficient and targeted.\n'
        },
    ];

    const toggleItem = (index: number) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    return (
        <div className="space-y-2">
            {faqs.map((faq, index) => (
                <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    list={faq.list}
                    conclusion={faq.conclusion}
                    isOpen={openIndex === index}
                    onClick={() => toggleItem(index)}
                />
            ))}
        </div>
    );
};

export default FAQAccordion;