import React, {useEffect} from "react";
import Image from "next/image";
import Link from 'next/link';
import {EmblaOptionsType} from 'embla-carousel'
import FAQAccordion from "@/components/accordion";
import PricingCard from "@/components/pricingCard"
import FeedbackCarousel from "@/components/feedbacksCarousel";
import MentorsCarousel from "@/components/mentorsCarousel";
import mentors from "@/constatns/mentors"
import {useToast} from "@/components/use-toast";
import {Skeleton} from "@/components/skeleton";
import RedirectIcon from "../../public/icons/redirect.svg";
import LocationIcon from "../../public/icons/location.svg";
import "./home.scss";
import {Job, Jobs} from "@/types/job";
import Head from "next/head";

export default function Home() {
    // const hasGoodConnection = typeof navigator !== 'undefined' && navigator.connection ? navigator.connection.downlink > 2 : true;
    const feedbackOptions: EmblaOptionsType = {axis: 'y', loop: true};
    const feedbackSlides = [
        {
            title: 'Senior DevOps Engineer',
            company: 'Taxfix',
            feedback: 'In our mock interviews, I got ready for almost everything and learned how to handle complex salary negotiations, and in the end, I got far better than I expected! Totally recommended!'
        },
        {
            title: 'Data Scientist',
            company: 'NN',
            feedback: 'My HR Mentor takes the time to understand your goals and then offers tailored advice. She gave me valuable tips on how to showcase my strengths and I got a great job offer.'
        },
        {
            title: 'DevOps Engineer',
            company: 'Adjoe',
            feedback: 'My HR mentor provided valuable insights for negotiation, ensuring I secured the best offer. I highly recommend her services to anyone seeking career advancement.'
        },
        {
            title: 'Data Engineer',
            company: 'Obloo',
            feedback: 'My mentor\'s most remarkable quality lies in her deep understanding of the dynamics of European countries\' job market. She effortlessly combines theory with practical insights.'
        }
    ];
    let skeletonJobs: number[] = new Array(3).fill("");

    const [jobs, setJobs] = React.useState<Jobs>({total: 0, pages: 0, positions: []})
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const {toast} = useToast()

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const res = await fetch(`https://api.expatpie.com/v0/jobs/2`)
                const data: Jobs = await res.json()
                const positions = data.positions
                const randomPositionIndex = Math.floor(Math.random() * (positions.length - 3) + 3);
                const randomPositions = data.positions.slice(randomPositionIndex - 3, randomPositionIndex)
                data.positions = randomPositions

                data.total = Math.floor(data.total / 100) * 100;
                setJobs(data)
                setIsLoading(false)
            } catch (e) {
                toast({
                    className: "bg-darker",
                    variant: "destructive",
                    title: "Uh oh! Something went wrong in job list.",
                    description: "Fail to fetch. please try again later",
                })
            }
        }
        fetchJobs()
    }, []);

    const OPTIONS: EmblaOptionsType = {}
    return (
        <>
            <Head>
                <title>Expat Pie</title>
                <link rel="canonical" href="https://expatpie.com/" />
                <meta property="og:description" content="Find your dream job and get help from professional mentors" />
                <meta property="og:url" content="https://expatpie.com/" />
                <meta name="description" content="Find your dream job and get help from professional mentors" />
                <meta property="og:title" content="Expat Pie"/>
            </Head>
            <main className="min-h-screen">
                {/*header*/}
                <div className="relative flex justify-center">
                    {/*<img src="/images/sample/header.png" alt="mentorship" className="w-full" />*/}
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        poster="/images/sample/header.png"
                        preload="metadata"
                        className="object-cover w-full home__video sm:max-h-[470px] lg:max-w-[1358px]"
                        style={{objectPosition: '50% 35%'}}
                    >
                        <source src="/video/header.webm" type="video/webm"/>
                        {/*<source src={src.replace('.webm', '.mp4')} type="video/mp4" />*/}
                        {/*Sorry, your browser does not support embedded videos.*/}
                    </video>

                    {/*vide*/}
                    {/*<div className="fixed top-0 left-0 right-0 bottom-0 z-[-1] overflow-hidden">*/}
                    {/*    {hasGoodConnection && (*/}
                    {/*        <video*/}
                    {/*            autoPlay*/}
                    {/*            loop*/}
                    {/*            muted*/}
                    {/*            playsInline*/}
                    {/*            poster="/images/sample/header.png"*/}
                    {/*            preload="metadata"*/}
                    {/*            className="object-cover w-full h-full"*/}
                    {/*        >*/}
                    {/*            <source src="/video/header.webm" type="video/webm" />*/}
                    {/*            <source src={src.replace('.webm', '.mp4')} type="video/mp4" />*/}
                    {/*            Sorry, your browser does not support embedded videos.*/}
                    {/*        </video>*/}
                    {/*    )}*/}
                    {/*    {!hasGoodConnection && (*/}
                    {/*        <img src="/images/sample/header.png" alt="Background" className="object-cover w-full h-full" />*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    {/*end video*/}
                    <div className="absolute bg-gradient-to-b from-blur-0 to-blur-100 w-full h-full bottom-0">
                    </div>
                    <div className="absolute bg-gradient-to-l from-blur-0 to-blur-100 w-full h-full bottom-0">
                    </div>
                    <div className="absolute backdrop-blur-[3px] lg:backdrop-blur-[9px] w-full h-full bottom-0">
                    </div>
                    <div className="pt-16 absolute top-0 text-center lg:px-0 px-8">
                        <div className="font-semibold text-white text-4xl sm:text-42 font-barlow">
                            <p className="font-barlow">FIND YOUR DREAM JOB</p>
                            <p className="font-light mt-1">and get help from our</p>
                            <p className="mt-1">PROFESSIONAL MENTORS</p>
                        </div>
                        <p className="mt-5 text-white-68 text-2xl lg:text-28 font-bold">
                            <span className="font-normal mr-2.5">Boost your</span>
                            Interview Process
                        </p>
                        <div className="lg:mt-6 mt-5 flex justify-center">
                            <Link href="/mentors">
                                <button className="py-2.5 px-4 text-white font-semibold text-base bg-Accent-100 mr-5">
                                    Book a session
                                </button>
                            </Link>
                            <Link href="/jobs">
                                <button className="text-base font-semibold py-2.5 px-4 text-white border border-white">
                                    Find a job
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/*mentors*/}
                <div className="max-w-full flex flex-col items-center mt-[120px] sm:mt-0">
                    <MentorsCarousel slides={mentors} options={OPTIONS}/>
                    <div className="flex justify-center">
                        <Link href="/mentors">
                            <button className="py-2.5 px-4 mt-20 text-white font-semibold text-base bg-Accent-100">
                                Book a session
                            </button>
                        </Link>
                    </div>
                </div>
                <div id="customer-stories" className="flex flex-col items-center pt-[120px] lg:pt-[200px]">
                    <div
                        className="px-9 lg:px-0 w-full lg:w-[94%] min-[1100px]:w-[87%] min-[1189px]:w-[89%] min-[1220px]:w-4/5 xl:w-9/12 min-[1450px]:w-8/12 min-[1450px]:max-w-[960px]">
                        {/*feedbacks*/}
                        <div>
                            <FeedbackCarousel slides={feedbackSlides} options={feedbackOptions}/>
                        </div>
                        {/*steps*/}
                        <div
                            className="flex flex-col lg:flex-row gap-10 lg:gap-0 items-center lg:items-start lg:justify-between relative w-full mt-[120px] lg:mt-[200px]">
                            <div className="flex flex-col items-center max-w-[334px] lg:max-w-[180px] text-center">
                                <Image src="/images/step2.svg" alt="Find Mentor" width={160} height={90}/>
                                <p className="hidden lg:block mt-5 text-white-30 font-semibold text-28 z-10 px-7 bg-blur-100 font-barlow">
                                    1
                                </p>
                                <p className="font-bold text-32 text-white mt-4 font-barlow">
                                    Apply for Mentorship
                                </p>
                                <p className="mt-3 text-white-50 text-base font-normal">
                                    Begin your journey by finding an HR  mentor who can help you to create or refine your CV, and make your job search more effective.
                                </p>
                            </div>
                            <span
                                className="hidden lg:block border-dashed border-white-16 border-2 h-0 absolute w-10/12 left-[10%] top-[25%]"></span>
                            <div className="flex flex-col items-center max-w-[334px] lg:max-w-[180px] text-center">
                                <Image src="/images/step1.svg" alt="Find job" width={160} height={90}/>
                                <p className="hidden lg:block mt-5 text-white-30 font-semibold text-28 z-10 px-7 bg-blur-100 font-barlow">
                                    2
                                </p>
                                <p className="font-bold text-32 text-white mt-4 font-barlow w-2/3">
                                    Apply for Jobs
                                </p>
                                <p className="mt-3 text-white-50 text-base font-normal">
                                    Explore Our Job Platform for job positions that offer visa sponsorship and relocation support
                                </p>
                            </div>
                            <div className="flex flex-col items-center max-w-[334px] lg:max-w-[180px] text-center">
                                <Image src="/images/step3.svg" alt="Prepare for Interviews" width={160} height={90}/>
                                <p className="hidden lg:block mt-5 text-white-30 font-semibold text-28 z-10 px-7 bg-blur-100 font-barlow">
                                    3
                                </p>
                                <p className="font-bold text-32 text-white mt-4 font-barlow">
                                    Prepare for Interviews
                                </p>
                                <p className="mt-3 text-white-50 text-base font-normal">
                                    Prepare with mock interviews covering both HR and technical. Review your technical tasks before submission with our mentors, ensuring you're fully prepared for your technical interview.
                                </p>
                            </div>
                            <div className="flex flex-col items-center max-w-[334px] lg:max-w-[180px] text-center">
                                <Image src="/images/step4.svg" alt="step 1" width={160} height={90}/>
                                <p className="hidden lg:block mt-5 text-white-30 font-semibold text-28 z-10 px-7 bg-blur-100 font-barlow">
                                    4
                                </p>
                                <p className="font-bold text-32 text-white mt-4 font-barlow">
                                    Negotiate & Sign Offer
                                </p>
                                <p className="mt-3 text-white-50 text-base font-normal">
                                    Review your job offers with your mentor’s assistance. Negotiate the best terms for your contract, including salary, benefits, and relocation support.
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center mt-14 lg:mt-9">
                            <Link href="/mentors">
                                <button className="py-2.5 px-4 lg:mt-20 text-white font-semibold text-base bg-Accent-100">
                                    Book a session
                                </button>
                            </Link>
                        </div>
                        {/*pricing*/}
                        <p className="text-white text-2xl font-bold text-center mb-10 mt-[120px] lg:mt-[200px]">Fair
                            price</p>
                        <div className="flex flex-col gap-6 lg:flex-row items-center justify-between">
                            <PricingCard isMain={false} session={3} discount={5}/>
                            <PricingCard isMain={true} session={5} discount={10}/>
                            <PricingCard isMain={false} session={7} discount={15}/>
                        </div>
                        <p className="mt-10 font-normal text-white-68 text-lg text-center">Mentors price are different (€70
                            - €130)</p>
                        {/*positions*/}
                        <p className="mt-[120px] lg:mt-[200px] text-white font-bold text-2xl text-center">Job list</p>
                        <p className="mt-5 text-white-50 text-lg font-normal text-center">Increase your chance by using our
                            job list</p>
                        <div className="w-full relative mt-10">
                            {isLoading ? skeletonJobs.map((_, index: number) => <div
                                className="flex items-center justify-start w-full border-b border-white-16 px-4 py-4"
                                key={index}>
                                <Skeleton className="w-14 h-14 rounded-none"/>
                                <div className="ml-3 w-5/6">
                                    <div className="flex items-center">
                                        <Skeleton className="h-5 w-1/2 rounded-none"/>
                                    </div>
                                    <div className="flex flex-col items-start lg:flex-row lg:items-center mt-1 w-full">
                                        <div className="flex items-center lg:mr-2.5 w-1/5">
                                            <Skeleton className="h-5 w-full rounded-none"/>
                                        </div>
                                        <div className="flex items-center mt-1 lg:mt-0 w-1/5">
                                            <Skeleton className="h-5 w-full rounded-none"/>
                                        </div>
                                    </div>
                                </div>
                            </div>) : ''}
                            {!isLoading ? jobs.positions.map((item: Job, index: number) => <div
                                className="flex items-center justify-start w-full border-b border-white-16 px-4 py-4"
                                key={index}>
                                <Image src={`/images/companies/${item.source.split(".")[0].toLowerCase()}.svg`}
                                       alt={item.company.split(".")[0]} width={56} height={56}/>
                                <div className="ml-3">
                                    <div className="flex items-center">
                                        <p className="text-white text-lg font-semibold">{item.title}</p>
                                        <RedirectIcon className="hidden ml-2.5 group-hover:block"/>
                                    </div>
                                    <div className="flex flex-col items-start lg:flex-row lg:items-center mt-1">
                                        <div className="flex items-center lg:mr-2.5 mt-1 lg:mt-0">
                                            <LocationIcon className="w-5 h-5"/>
                                            <p className="ml-2 text-white-68 font-normal text-base">{item.location}</p>
                                        </div>
                                        <div className="flex items-center mt-1 lg:mt-0">
                                            <span className="mr-2.5 text-white-30 text-lg font-semibold">•</span>
                                            <p className="text-white-50 font-normal text-base">{item.company}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>) : ''}
                            <div className="absolute bg-gradient-to-b from-blur-0 to-blur-100 w-full h-4/6 bottom-0">
                            </div>
                        </div>
                        {isLoading ? <div
                                className="mt-5 font-semibold text-5xl text-white font-barlow flex items-start lg:items-end justify-center">
                                <Skeleton className="h-9 w-10 rounded-none mr-2 lg:mt-0 mt-3"/>
                                <p className="ml-1">hand picked jobs</p>
                            </div> :
                            <p className="mt-5 text-center font-semibold text-5xl text-white font-barlow">
                                +{jobs.total} hand picked jobs
                            </p>}
                        <div className="flex justify-center my-5">
                            <Link href="/jobs">
                                <button className="py-2.5 px-4 text-white font-semibold text-base bg-Accent-100">
                                    Explore jobs
                                </button>
                            </Link>
                        </div>
                        {/*faq*/}
                        <div className="w-full py-[120px] lg:py-[200px]" id="faqs">
                            <p className="text-white font-bold text-2xl text-left mb-10">Frequently Asked Questions</p>
                            <FAQAccordion/>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
