import React from "react";
import Link from "next/link"

type PricingCardProps = {
    isMain: boolean;
    session: number;
    discount: number;
};

const PricingCard: React.FC<PricingCardProps> = ({isMain, session, discount}) => {
    return (
        <div className={`border-2 pb-8 pt-10 px-[75px] text-center group transition-all hover:transition-all hover:pt-8 hover:pb-10 hover:bg-white-8 hover:border-white-50 hover:shadow-accent ${isMain ? 'border-white-50' : 'border-white-30'}`}>
            <p className="text-white whitespace-nowrap font-semibold text-2xl py-2 px-4 group-hover:rounded-full group-hover:bg-darker">{session} sessions</p>
            <p className="text-white-68 text-lg	font-normal mt-4">with</p>
            <p className={`font-semibold text-5xl font-barlow ${isMain ? 'text-white' : 'text-white-80'}`}>%{discount}</p>
            <p className="text-white-68 text-lg	font-normal">discount</p>
            <Link href="/mentors">
            <button className={`py-2.5 px-4 mt-6 text-white font-semibold text-base group-hover:bg-Accent-100 ${isMain ? 'bg-Accent-100' : 'bg-white-30'}`}>
                Get started
            </button>
            </Link>
        </div>
    );
};

export default PricingCard;
