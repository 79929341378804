
import React, { useState, useEffect, useCallback } from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import "./feedbacks.scss"
import Image from "next/image";

type PropType = {
    slides: SlideType[]
    options?: EmblaOptionsType
}

type SlideType = {
    title: string;
    company: string;
    feedback: string
}

type ThumbPropType = {
    selected: boolean
    imgSrc: string
    index: number
    onClick: () => void
}

const Thumb: React.FC<ThumbPropType> = (props) => {
    const { selected, imgSrc, index, onClick } = props

    return (
        <div className="py-3">
        <div
            className={'feedbacks-thumbs__slide pl-4 py-1 flex hover:pl-3.5 hover:border-l-2 hover:border-white-50'.concat(
                selected ? ' feedbacks-thumbs__slide--selected border-l-2 border-Accent-100 hover:pl-4' : ''
            )}
        >
            {selected ? <div className="feedbacks__progress mr-3">
                <svg>
                    <circle r="14" cx="16" cy="16"></circle>
                </svg>
            </div> : <Image src="/icons/arrowRight.svg" alt="arrow right" width={32} height={32} className="mr-3" />}
            <button
                onClick={onClick}
                className="feedbacks-thumbs__slide__button py-1 px-3.5"
                type="button"
            >
                <Image
                    className="block object-cover"
                    src={`/icons/${imgSrc.toLowerCase()}.svg`}
                    alt="company"
                    width={120}
                    height={30}
                />
            </button>
        </div>
        </div>
    )
}

const FeedbackCarousel: React.FC<PropType> = (props) => {
    const { slides, options } = props
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options, [Autoplay()])
    const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
        containScroll: 'keepSnaps',
        dragFree: true
    })

    const onThumbClick = useCallback(
        (index: number) => {
            if (!emblaMainApi || !emblaThumbsApi) return
            emblaMainApi.scrollTo(index)
        },
        [emblaMainApi, emblaThumbsApi]
    )

    const onSelect = useCallback(() => {
        if (!emblaMainApi || !emblaThumbsApi) return
        setSelectedIndex(emblaMainApi.selectedScrollSnap())
        emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap())
    }, [emblaMainApi, emblaThumbsApi, setSelectedIndex])

    useEffect(() => {
        if (!emblaMainApi) return
        onSelect()
        emblaMainApi.on('select', onSelect)
        emblaMainApi.on('reInit', onSelect)
    }, [emblaMainApi, onSelect])

    return (
        <div className="flex flex-col-reverse lg:flex-row justify-center">
            <div className="feedbacks-thumbs mt-4 lg:mt-0 lg:mr-10">
                <div className="overflow-hidden" ref={emblaThumbsRef}>
                    <div
                        className="flex flex-col gap-4"
                    >
                        {slides.map((item, index) => (
                            <Thumb
                                onClick={() => onThumbClick(index)}
                                selected={index === selectedIndex}
                                index={index}
                                imgSrc={item.company}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="feedbacks lg:w-3/5 lg:pl-10 lg:border-l lg:border-white-16">
                <div className="overflow-hidden" ref={emblaMainRef}>
                    <div className="feedbacks__container">
                        {slides.map((item, index) => (
                            <div className="feedbacks__slide" key={index}>
                                <div>
                                    <div className="flex lg:pt-1">
                                        <div className="hidden lg:block p-4 bg-white-16 max-h-fit h-fit rounded">
                                            <Image src="/icons/avatar.svg" alt="avatar" width={30} height={30} />
                                        </div>
                                        <p className="font-normal text-white-30 text-2xl lg:ml-6">Former mentee, currently working at <br/><span className="text-white-100">{item.company}</span> as a <span className="text-white-100">{item.title}</span></p>
                                    </div>
                                    <p className="text-white font-medium text-32 mt-6 font-barlow">
                                        “{item.feedback}”
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackCarousel
