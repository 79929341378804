import React, {useState, useEffect, useCallback, PropsWithChildren} from 'react'
import {EmblaCarouselType, EmblaOptionsType} from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import Image from "next/image";
import Link from "next/link"
import "./mentors.scss"


type PropType = PropsWithChildren<
    React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >
>

export const PrevButton: React.FC<PropType> = (props) => {
    const {children, ...restProps} = props

    return (
        <button
            className="embla__button z-10 flex items-center justify-center cursor-pointer px-4 py-3 bg-white-30"
            type="button"
            {...restProps}
        >
            <Image src="/icons/chevronDown.svg" alt="chevron down" width={20} height={20} className="rotate-90"/>
        </button>
    )
}

export const NextButton: React.FC<PropType> = (props) => {
    const {children, ...restProps} = props

    return (
        <button
            className="embla__button z-10 flex items-center justify-center cursor-pointer px-4 py-3 bg-white-30"
            type="button"
            {...restProps}
        >
            <Image src="/icons/chevronDown.svg" alt="chevron down" width={20} height={20} className="-rotate-90"/>
        </button>
    )
}

type MentorType = {
    id: number,
    fullName: string,
    jobTitle: string
}

type EmblaCarouselPropType = {
    slides: MentorType[],
    options?: EmblaOptionsType
}

const MentorsCarousel: React.FC<EmblaCarouselPropType> = (props) => {
    const {slides, options} = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options)
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

    const scrollPrev = useCallback(
        () => emblaApi && emblaApi.scrollPrev(),
        [emblaApi]
    )
    const scrollNext = useCallback(
        () => emblaApi && emblaApi.scrollNext(),
        [emblaApi]
    )
    const scrollTo = useCallback(
        (index: number) => emblaApi && emblaApi.scrollTo(index),
        [emblaApi]
    )

    const onInit = useCallback((emblaApi: EmblaCarouselType) => {
        setScrollSnaps(emblaApi.scrollSnapList())
    }, [])

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onInit(emblaApi)
        onSelect(emblaApi)
        emblaApi.on('reInit', onInit)
        emblaApi.on('reInit', onSelect)
        emblaApi.on('select', onSelect)
    }, [emblaApi, onInit, onSelect])

    return (
        <>
            <div className="embla max-w-full">
                <div className="flex justify-between items-center px-9 lg:px-[140px]">
                    <div>
                        <p className="text-white text-2xl font-bold py-1.5">Popular mentors</p>
                    </div>
                    <div className="flex items-center justify-end">
                        <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled}/>
                        <NextButton onClick={scrollNext} disabled={nextBtnDisabled}/>
                    </div>
                </div>
                <div className="my-10 flex gap-3.5 px-9 lg:px-[140px]">
                    <Link href="/mentors?category=Product"
                          className="border border-white-30 text-white-100 font-semibold max-w-fit px-4 py-1.5 text-center">
                        Product
                    </Link>
                    <Link href="/mentors?category=Engineering"
                          className="border border-white-30 text-white-100 font-semibold max-w-fit px-4 py-1.5 text-center">
                        Engineering
                    </Link>
                    <Link href="/mentors?category=HR"
                          className="border border-white-30 text-white-100 font-semibold max-w-fit px-4 py-1.5 text-center">
                        HR
                    </Link>
                </div>
                <div className="embla__viewport pl-9 lg:pl-[140px]" ref={emblaRef}>
                    <ul className="embla__container">
                        {slides.map((item, index) => (
                            <li className="embla__slide" key={item.id}>
                                <Link href={`/mentor/${item.fullName.toLowerCase().split(" ").join("-")}`}>
                                    {/*<img*/}
                                    {/*    className="embla__slide__img"*/}
                                    {/*    src={`/images/sample/mentor.svg`}*/}
                                    {/*    alt="Your alt text"*/}
                                    {/*/>*/}

                                    <div className="relative">
                                        <div className="overflow-hidden">
                                            <Image
                                                className={'hover:scale-110 transition duration-300 ease-in-out'}
                                                src={`/images/mentors/${item.fullName.toLowerCase().split(" ").join("-")}.jpg`}
                                                alt={item.fullName}
                                                width={200}
                                                height={260}
                                                quality={75}
                                                placeholder="blur"
                                                loading="lazy"
                                                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mMUqwcAALEAlw+6NSgAAAAASUVORK5CYII="
                                            />
                                        </div>
                                        <div
                                            className="absolute bg-gradient-to-b from-blur-0 to-blur-100 lg:w-full h-1/5 -bottom-px w-[101%] -left-px lg:left-0">
                                        </div>
                                        <p className="text-32 font-bold font-barlow text-white-90 absolute bottom-0">{item.fullName.split(" ")[0]}</p>
                                    </div>
                                    <div className="text-left">
                                        {/*<p className="text-32 font-bold text-white-90">{item.fullName.split(" ")[0]}</p>*/}
                                        <p className="text-32 font-bold font-barlow text-white-90 leading-none">{item.fullName.split(" ")[1]}</p>
                                        <p className="text-lg font-normal text-white-50 mt-5">{item.jobTitle}</p>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default MentorsCarousel
