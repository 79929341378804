import { cn } from "@/utils/tailwindMerge"

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={cn("animate-pulse rounded-md bg-dark-gray", className)}
            {...props}
        />
    )
}

export { Skeleton }